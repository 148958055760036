<template>
  <v-container class="pt-0">
    <v-form v-model="valid">
      <v-card elevation="0">
        <v-card-title class="pb-0">
          {{ $t("courierSet.payment").toUpperCase() }}
        </v-card-title>
        <v-container>
          <v-row class="pb-5">
            <v-divider />
          </v-row>
          <v-row>
            <v-col>
              <text-field
                v-model="externalClientSymbol"
                :title=" $t('courierSet.externalClientSymbol')"
                @input="update"
              />
            </v-col>
            <v-col>
              <text-field
                v-model="iban"
                :title=" $t('courierSet.iban')"
                rules="iban"
                @input="update"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card
        elevation="0"
        class="mt-4"
      >
        <v-card-title class="pb-0">
          {{ $t("courierSet.insurance").toUpperCase() }}
        </v-card-title>
        <v-container>
          <v-row class="pb-5">
            <v-divider />
          </v-row>
          <v-row>
            <v-col>
              <text-field
                v-model="amountInsurance"
                :title=" $t('courierSet.insuranceAmount')"
                rules="double:2"
                @input="update"
              />
            </v-col>
            <v-col>
              <select-field
                v-model="currencyInsurance"
                :title=" $t('courierSet.defaultCurrency')"
                :filed-items="currencyItems"
                @input="update"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import SelectField from '@/components/inputs/SelectField.vue';
import { currencyItems } from '@/constants/CurrencyItems';

export default {
  components: {
    TextField, SelectField,
  },
  props: {
    confData: { type: Object, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: null },
  },

  data: () => ({
    valid: null,

    confSet: null,
    insurance: null,
    insuranceId: null,
    additionalPayments: null,
    cod: null,
    codId: null,
    tenantId: null,
    amountInsurance: null,
    currencyInsurance: null,
    codAmount: null,
    codCurrency: null,
    externalClientSymbol: null,
    iban: null,
    currencyItems,

  }),
  created() {
    this.getConfSet();
  },
  methods: {
    update() {
      this.cod = {
        clientEntityState: this.isNew ? 4 : 3,
        id: this.codId,
        tenantId: this.tenantId,
        amount: this.codAmount,
        currency: this.codCurrency,
        externalClientSymbol: this.externalClientSymbol,
        iban: this.iban,
      };
      this.insurance = {
        clientEntityState: this.isNew ? 4 : 3,
        id: this.insuranceId,
        tenantId: this.tenantId,
        amount: this.amountInsurance,
        currency: this.currencyInsurance,
      };
      this.additionalPayments = {
        parameters: {
          cod: this.cod,
          insurance: this.insurance,
        },
      };
      this.$emit('input', this.additionalPayments);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.cod = this.confSet?.geisDefaultValues?.cashOnDelivery;
      this.insurance = this.confSet?.geisDefaultValues?.insurance;

      this.codId = this.cod?.id ? this.cod?.id : null;
      this.insuranceId = this.insurance?.id ? this.insurance?.id : null;
      this.tenantId = this.cod?.tenantId ? this.cod?.tenantId : null;

      this.codAmount = this.cod?.amount ? this.cod?.amount : null;
      this.codCurrency = this.cod?.currency ? this.cod?.currency : null;
      this.externalClientSymbol = this.cod?.externalClientSymbol
        ? this.cod?.externalClientSymbol : null;
      this.iban = this.cod?.iban ? this.cod?.iban : null;

      this.amountInsurance = this.insurance?.amount ? this.insurance?.amount : null;
      this.currencyInsurance = this.insurance?.currency ? this.insurance?.currency : null;
    },

  },
};
</script>

<style scoped lang="scss">
.v-select{
  padding: 0px 10px 0px 10px;
}
.col{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
